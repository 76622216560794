<template>
    <div class="module-cont-box">
        <div class="about-main-cont">
            <a-row type="flex" justify="space-around">
                <a-col :lg="12">
                    <div class="main-title">关于我们</div>
                    <span class="circle-icon"></span><span style="font-size:16px;">ABOUT US</span>
                    <div class="main-des">{{ info.desc }}</div>
                </a-col>
                <a-col :lg="12">                    
                    <div class="img-wrap">
                        <img :src="info.img1" style="height:120px;margin-top:60px;"/>
                        <img :src="info.img2"/>
                        <img :src="info.img3" style="height:120px;margin-top:30px;"/>
                    </div>
                </a-col>
            </a-row>
        </div>
        <div class="about-sub-cont">
            <a-row type="flex" justify="space-around">
                <a-col v-for="(item,index) in info.subList" :key="index"  :lg="8">
                    <div class="sub-item">
                        <div class="sub-num">
                            <span class="num-txt">{{ String(index+1).padStart(2, '0') }}</span>
                            <a-icon class="about-sub-icon" type="code-sandbox"  />
                        </div>
                        <div class="sub-txt">
                            <div class="sub-title txt-over-ellipsis">{{item.title }}</div>
                            <ul>
                                <li v-for="(point,index) in item.points">{{point}}</li>
                            </ul>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
    export default{
        name:'abount',
        data(){
            return{
                info:{},
                subList:[],
            }
        },
        created(){
            this.loadInfo();
        },
        mounted() {
            // this.loadInfo();
        },
        methods:{
            loadInfo(){
                // this.$service.get('getAboutInfo').then(resp=>{
                //     this.info=resp.data;
                //     console.log(this.info);
                // });
                this.$service.Ers76.getContent({"did":"a3827950b0cb11efba8546cc601cd7e1", "status":1}).then(resp => {
                    console.log("about getContent a3827950b0cb11efba8546cc601cd7e1", resp);
                    if(resp && resp.data)
                    {
                        this.info = resp.data;
                        this.info.desc = resp.data.summary;
                        this.$forceUpdate();
                    }
                });
                // this.$service.Ers76.listContent({"sid":"24612476b0c011efba8546cc601cd7e1", "status":1}).then(resp => {
                //     console.log("about listContent 24612476b0c011efba8546cc601cd7e1", resp);
                //     if(resp && resp.data)
                //     {
                //         for(let i = 0; i < resp.data.length; i++)
                //         {
                //             this.subList.push({
                //                 title: resp.data[i].title,
                //                 points: JSON.parse(resp.data[i].content)
                //             });
                //         }
                //     }
                // });
            }
        }
    }
</script>
<style lang="less" scoped>
    // .txt-over-ellipsis{text-overflow: ellipsis;overflow: hidden; white-space: nowrap;}
    .about-main-cont{
         margin-bottom:30px;        
        .main-title{font-size: 20px; margin-bottom: 6px;}
        .circle-icon{border:3px solid @primary-color;border-radius: 100px; width:13px;height: 13px;display: inline-block;margin-right:5px;}
        .main-des{font-size: 16px;line-height: 32px;margin-top: 30px;}
        .img-wrap{
            display: flex;margin-left:30px;
            img{margin-left: 10px;}
        }
    }
    .about-sub-cont{
        font-size: 16px;
        .sub-item{         
            margin-right: 20px;
            .sub-num{
                font-size: 35px;
                .num-txt{font-weight: bold; margin-right: 10px; opacity: 0.5;}
                .about-sub-icon{color: @primary-color;}
            }
            .sub-title{margin:20px 0 20px 25px; }
            ul li{opacity: 0.6;line-height: 32px;}
        }
    }
</style>